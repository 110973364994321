import {
  Col,
  InputNumber,
  Row,
  Tooltip,
  Whisper,
  Toggle,
  Button,
  MultiCascader,
} from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faXmark,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import "../style.scss";

const TributarioPermissions = ({
  // permissions,
  permissionsTrib,
  permissionsTrab,
  permissionKey,
  entities,
  changePermissionValue,
  onChangeEntities,
  groupArray,
  onChangeObject,
  onChangeAction,
  updateUserServiceSegmentation,
  loadSave,
}) => {
  return (
    <div className="user-permissions">
      {/* Nova Seção: Actions */}
      <Row className="filter-category">
        <h6 className="config-session-title">Features</h6>
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">Qtd MVBP</label>
          <br />
          <Whisper
            trigger="focus"
            placement="autoHorizontal"
            speaker={<Tooltip>Valor 0 significa quantidade ilimitada</Tooltip>}
          >
            <InputNumber
              min={-1}
              value={permissionsTrib?.actions?.search_labor_jurimetry ?? 0}
              onChange={(value) =>
                onChangeAction(permissionKey,"actions","search_labor_jurimetry", value)
              }
            />
          </Whisper>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">Qtd Resumos AI</label>
          <br />
          <Whisper
            trigger="focus"
            placement="autoHorizontal"
            speaker={<Tooltip>Valor 0 significa quantidade ilimitada</Tooltip>}
          >
            <InputNumber
              min={-1}
              disabled
              value={permissionsTrib?.actions?.simple_summary ?? 0}
              onChange={(value) =>
                onChangeAction(permissionKey,"actions","simple_summary", value)
              }
            />
          </Whisper>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">Qtd Resumo 10+</label>
          <br />
          <Whisper
            trigger="focus"
            placement="autoHorizontal"
            speaker={<Tooltip>Valor 0 significa quantidade ilimitada</Tooltip>}
          >
            <InputNumber
              min={0}
              disabled
              value={permissionsTrib?.actions?.geral_summary ?? 0}
              onChange={(value) =>
                onChangeAction(permissionKey,"actions","geral_summary", value)
              }
            />
          </Whisper>
        </Col>
      </Row>


      <Row className="filter-category">
        <h6 className="config-session-title">Permissões básicas</h6>
        <Col xs={24} sm={24} md={8}>
          <label className="config-session-subtitle">Qtdd Pesquisas</label>
          <br />
          <Whisper
            trigger="focus"
            placement="autoHorizontal"
            speaker={<Tooltip>Valor 0 significa quantidade ilimitada</Tooltip>}
          >
            <InputNumber
              min={0}
              value={permissionsTrib?.qt_pesquisas ?? ""}
              onChange={(value) =>
                changePermissionValue(permissionKey, "qt_pesquisas", value)
              }
            />
          </Whisper>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <label className="config-session-subtitle">Qtdd Monitoramentos</label>
          <br />
          <Whisper
            trigger="focus"
            placement="autoHorizontal"
            speaker={<Tooltip>Valor 0 significa quantidade ilimitada</Tooltip>}
          >
            <InputNumber
              min={0}
              value={permissionsTrib?.qt_monitoramento ?? ""}
              onChange={(value) =>
                changePermissionValue(permissionKey, "qt_monitoramento", value)
              }
            />
          </Whisper>
          {permissionsTrib?.qt_monitoramento === null ? (
            <small className="clickable-selection">
              Monitoramento está desabilitado para este usuário
            </small>
          ) : (
            <small
              className="clickable-selection disable"
              onClick={() =>
                changePermissionValue(permissionKey, "qt_monitoramento", null)
              }
            >
              <FontAwesomeIcon size={"xs"} icon={faTriangleExclamation} />
              Clique aqui para desabilitar o monitoramento
            </small>
          )}
        </Col>
        <Col xs={24} sm={24} md={8}>
          <label className="config-session-subtitle">Qtdd Coleções</label>
          <br />
          <Whisper
            trigger="focus"
            placement="autoHorizontal"
            speaker={<Tooltip>Valor 0 significa quantidade ilimitada</Tooltip>}
          >
            <InputNumber
              min={0}
              value={permissionsTrib?.qt_boards ?? ""}
              onChange={(value) => {
                changePermissionValue(permissionKey, "qt_boards", value);
              }}
            />
          </Whisper>
          {permissionsTrib?.qt_boards === null ? (
            <small className="clickable-selection">
              Coleções estão desabilitadas para este usuário
            </small>
          ) : (
            <small
              className="clickable-selection disable"
              onClick={() => {
                changePermissionValue(permissionKey, "qt_boards", null);
              }}
            >
              <FontAwesomeIcon size={"xs"} icon={faTriangleExclamation} />{" "}
              Clique aqui para desabilitar as coleções
            </small>
          )}
        </Col>
      </Row>

      <Row className="filter-category">
        <h6 className="config-session-title">Órgãos</h6>
        <Col xs={24} sm={24} md={8}>
          <label className="config-session-subtitle">Órgãos da Pesquisa</label>
          <br />
          <MultiCascader
            value={permissionsTrib?.entidades}
            block
            placeholder="Selecione os órgãos"
            data={groupArray(entities, "class")}
            onChange={(value) =>
              onChangeEntities(permissionKey, "entidades", value)
            }
            columnWidth={200}
          />
          <small>Copiar de: </small>
          <small
            className="clickable-selection disable"
            onClick={() =>
              onChangeEntities(
                permissionKey,
                "entidades",
                permissionsTrib?.orgaos_monitorados,
              )
            }
          >
            Órgãos do Monitoramento
          </small>
          <b> | </b>
          <small
            className="clickable-selection disable"
            onClick={() =>
              onChangeEntities(
                permissionKey,
                "entidades",
                permissionsTrib?.dataviz_entidades,
              )
            }
          >
            Órgãos da Jurimetria
          </small>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <label className="config-session-subtitle">
            Órgãos do Monitoramento
          </label>
          <br />
          <MultiCascader
            value={permissionsTrib?.orgaos_monitorados}
            block
            placeholder="Selecione os órgãos"
            data={groupArray(entities, "class")}
            onChange={(value) =>
              onChangeEntities(permissionKey, "orgaos_monitorados", value)
            }
            columnWidth={200}
          />
          <small>Copiar de: </small>
          <small
            className="clickable-selection disable"
            onClick={() =>
              onChangeEntities(
                permissionKey,
                "orgaos_monitorados",
                permissionsTrib?.entidades,
              )
            }
          >
            Órgãos de Pesquisa
          </small>
          <b> | </b>
          <small
            className="clickable-selection disable"
            onClick={() =>
              onChangeEntities(
                permissionKey,
                "orgaos_monitorados",
                permissionsTrib?.dataviz_entidades,
              )
            }
          >
            Órgãos de Jurimetria
          </small>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <label className="config-session-subtitle">
            Órgãos da Jurimetria
          </label>
          <br />
          <MultiCascader
            value={permissionsTrib?.dataviz_entidades}
            block
            placeholder="Selecione os órgãos"
            data={groupArray(entities, "class")}
            onChange={(value) =>
              onChangeEntities(permissionKey, "dataviz_entidades", value)
            }
            columnWidth={200}
          />
          <small>Copiar de: </small>
          <small
            className="clickable-selection disable"
            onClick={() =>
              onChangeEntities(
                permissionKey,
                "dataviz_entidades",
                permissionsTrib?.entidades,
              )
            }
          >
            Órgãos da Pesquisa
          </small>
          <b> | </b>
          <small
            className="clickable-selection disable"
            onClick={() =>
              onChangeEntities(
                permissionKey,
                "dataviz_entidades",
                permissionsTrib?.orgaos_monitorados,
              )
            }
          >
            Órgãos do Monitoramento
          </small>
        </Col>
      </Row>

      <Row className="filter-category" gutter={32}>
        <h6 className="config-session-title">Jurimetria (Resultados Geral)</h6>
        <Col xs={24} sm={12} md={12} className="filter-category_jurimetrics np">
          <label className="config-session-subtitle">
            Gráficos de Jurimetria da Busca
          </label>
          <br />
          <Toggle
            checked={permissionsTrib?.dataviz?.search_jurimetrics}
            size="md"
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(
                permissionKey,
                "dataviz",
                "search_jurimetrics",
                value,
              )
            }
          />
        </Col>
        <Col xs={24} sm={12} md={12} className="filter-category_jurimetrics np">
          <label className="config-session-subtitle">
            Gráficos de Jurimetria do Relator
          </label>
          <br />
          <Toggle
            checked={permissionsTrib?.dataviz?.relator_jurimetrics}
            size="md"
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(
                permissionKey,
                "dataviz",
                "relator_jurimetrics",
                value,
              )
            }
          />
        </Col>
      </Row>

      <Row className="filter-category" gutter={32}>
        <h6 className="config-session-title">Jurimetria Trabalhista</h6>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">Busca por CNPJ</label>
            <br />
            <Toggle
              checked={permissionsTrab?.jurimetria?.cnpj}
              size="md"
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject("trabalhista", "jurimetria", "cnpj", value)
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">Busca por Estado</label>
            <br />
            <Toggle
              checked={permissionsTrab?.jurimetria?.estado}
              size="md"
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject("trabalhista", "jurimetria", "estado", value)
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">Busca por Assunto</label>
            <br />
            <Toggle
              checked={permissionsTrab?.jurimetria?.assunto}
              size="md"
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject("trabalhista", "jurimetria", "assunto", value)
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="filter-category" gutter={32}>
        <h6 className="config-session-title">Filtros</h6>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">Filtro órgãos</label>
            <br />
            <Toggle
              size="md"
              checked={permissionsTrib?.filtros?.entity}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject(permissionKey, "filtros", "entity", value)
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">Ordenação</label>
            <br />
            <Toggle
              size="md"
              checked={permissionsTrib?.filtros?.sort}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject(permissionKey, "filtros", "sort", value)
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">Filtro por data</label>
            <br />
            <Toggle
              size="md"
              checked={permissionsTrib?.filtros?.date_range}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject(permissionKey, "filtros", "date_range", value)
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">
              Filtro de resultado da decisão
            </label>
            <br />
            <Toggle
              size="md"
              checked={permissionsTrib?.filtros?.filter_by_favorability}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject(
                  permissionKey,
                  "filtros",
                  "filter_by_favorability",
                  value,
                )
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">
              Filtro de tipo da decisão
            </label>
            <br />
            <Toggle
              size="md"
              checked={permissionsTrib?.filtros?.filter_by_vote}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject(
                  permissionKey,
                  "filtros",
                  "filter_by_vote",
                  value,
                )
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">
              Filtro por relator
            </label>
            <br />
            <Toggle
              size="md"
              checked={permissionsTrib?.filtros?.filter_by_relator}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject(
                  permissionKey,
                  "filtros",
                  "filter_by_relator",
                  value,
                )
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">
              Filtro por seção (CARF)
            </label>
            <br />
            <Toggle
              size="md"
              checked={permissionsTrib?.filtros?.filter_by_secao_carf}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject(
                  permissionKey,
                  "filtros",
                  "filter_by_secao_carf",
                  value,
                )
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">
              Filtro por órgão julgador (somente STF)
            </label>
            <br />
            <Toggle
              size="md"
              checked={permissionsTrib?.filtros?.filter_by_orgao_julgador_stf}
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject(
                  permissionKey,
                  "filtros",
                  "filter_by_orgao_julgador_stf",
                  value,
                )
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="filter-category no-line" gutter={32}>
        <h6 className="config-session-title">Business Dashboard</h6>
        <Col xs={24} sm={12} md={8} className="filter-category_filters np">
          <div className="filter-category_filters_container">
            <label className="config-session-subtitle">Área Empresarial</label>
            <br />
            <Toggle
              checked={permissionsTrab?.business_dashboard?.area_empresarial}
              size="md"
              checkedChildren={<FontAwesomeIcon icon={faCheck} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
              onChange={(value) =>
                onChangeObject(
                  "trabalhista",
                  "business_dashboard",
                  "area_empresarial",
                  value,
                )
              }
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={4} mdOffset={20}>
          <Button
            block
            className="btn-save"
            loading={loadSave}
            onClick={() => updateUserServiceSegmentation(permissionKey)}
            appearance="primary"
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TributarioPermissions;
