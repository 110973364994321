import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";

import { Turivius } from "../../ui";
import { IconButton, Button, Modal, Message, Table, toaster, Progress } from "rsuite";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TuriviusColors from "../../vars.module.scss";
import "./style.scss";
import { requester } from "../../requesters";

const { Column, HeaderCell, Cell } = Table;

import { Whisper, Tooltip } from "rsuite";

const CustomCell = ({ rowData, dataKey, ...props }) => {
  const cellText = rowData[dataKey] || ""; // Texto da célula

  return (
    <Cell {...props}>
      <Whisper
        placement="top"
        trigger="hover"
        speaker={<Tooltip>{cellText}</Tooltip>}
      >
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px", // Ajuste o tamanho conforme necessário
          }}
        >
          {cellText}
        </div>
      </Whisper>
    </Cell>
  );
};

const BulkCreateButton = ({ requiredFields, fieldFormats, company }) => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [errors, setErrors] = useState([]);
  const [successMsg, setSuccessMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [progress, setProgress] = useState(0);


  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      validateAndSetData(parsedData);
    };

    reader.readAsBinaryString(file);
  };

  const validateAndSetData = (parsedData) => {
    if (parsedData.length === 0) {
      setErrors(["O arquivo está vazio"]);
      setData([]);
      return;
    }

    // Pega a primeira linha como cabeçalho para validar se todos os campos necessários estão lá
    const fileHeaders = parsedData[0];
    const missingFields = requiredFields.filter(field => !fileHeaders.includes(field));

    // Verifica se tem campos ausentes
    if (missingFields.length > 0) {
      setErrors([`Campos ausentes: ${missingFields.join(", ")}`]);
      setData([]);
      return;
    }

    // Caso haja linhas que estão em branco ele simplesmente ignora
    const cleanedData = parsedData.filter(row => row.length > 0);

    // Se não tiver dados e mostra o erro de que não há nada a inserir
    if (cleanedData.length == 0) {
      setErrors([`Não há dados para serem inseridos!`]);
      setData([]);
      return;
    }

    const structuredData = cleanedData.slice(1).map((row, rowIndex) => {
      let obj = {};
      let rowErrors = [];
      fileHeaders.forEach((header, index) => {
        let value = row[index] || "";
        if (fieldFormats[header]) {
          const format = fieldFormats[header];
          if (format === "number" && isNaN(value)) {
            rowErrors.push(`Linha ${rowIndex + 1}: O campo '${header}' deve ser numérico.`);
          }
          if (format === "email" && !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value)) {
            rowErrors.push(`Linha ${rowIndex + 1}: O campo '${header}' deve ser um e-mail válido.`);
          }
        }
        obj[header] = value;
      });
      return { ...obj, errors: rowErrors };
    });

    const allErrors = structuredData.flatMap(row => row.errors);
    if (allErrors.length > 0) {
      setErrors(allErrors);
      setData([]);
      return;
    }

    // aqui eu add a info da empresa
    const validatedData = structuredData.map(obj => { return { ...obj, empresa: company.name } })
    // here we set name of column company
    const finalFields = [...fileHeaders, "empresa"]


    setHeaders(finalFields);
    setData(validatedData);
    setErrors([]);
    setSuccessMsg(["Arquivo validado com sucesso"]);
  };

  const checkBefore = async () => {
    let response = false
    setLoading(true);
    try {
      const updatedData = await Promise.all(
        data.map(item => requester.profiles.checkEmail(item.email))
      );

      // Adiciona a informação de existência ao objeto original
      const dataWithExistence = data.map((item, index) => ({
        ...item,
        exists: updatedData[index]?.exists, // Adiciona a flag de existência ao item
      }));

      // Filtra os e-mails que já existem
      const existingEmails = dataWithExistence.filter(item => item.exists);

      if (existingEmails.length > 0) {
        // Formata os erros para exibição
        const emailErrors = existingEmails.map(
          (item) => `O email ${item.email} já está cadastrado.`
        );

        // Atualiza a lista de erros
        setErrors((prevErrors) => [...emailErrors]);
        setSuccessMsg(null)
      } else {
        setErrors([]);
        setSuccessMsg("Está tudo pronto! Pode criar!");
        response = true;
      }


    } catch (err) {
      setSuccessMsg(null)

      setErrors(["Erro ao checar os emails! Confira o arquivo"]);
      response = false;
    } finally {
      setLoading(false);
    }

    return response
  };

  // EXPLANATION: Essa função controla o upload em lote
  const handleBatchSubmit = async (users) => {
    setLoading(true);

    for (const user of users) {
      try {
        await requester.admin.createUser(
          company?.id,
          company?.name,
          company?.cellphone,
          user.email,
          user.name,
          user.position
        );

        toaster.push(
          <Message closable duration={4000} type="success">
            Usuário {user.name} criado com sucesso!
          </Message>
        );


      } catch (err) {
        console.error(err);
        toaster.push(
          <Message closable duration={4000} type="error">
            Erro ao criar usuário {user.name}: {err.message}
          </Message>
        );
      }
    }

    setLoading(false);
  };


  // Função com barra de progresso
  const handleBatchSubmitProgress = async (users) => {
    setLoading(true);
    setProgress(0);

    const totalUsers = users.length;

    for (let i = 0; i < totalUsers; i++) {
      const user = users[i];

      try {
        await requester.admin.createUser(
          company?.id,
          company?.name,
          company?.cellphone,
          user.email,
          user.name,
          user.position
        );

        
        toaster.push(
          <Message closable type="success">
            Usuário {user.name} foi criado com sucesso!
          </Message>
        );

      } catch (err) {
        console.error(err);
        toaster.push(
          <Message closable duration={4000} type="error">
            Erro ao criar usuário {user.name}: {err.message}
          </Message>
        );
        setErrors(prev => [...prev , `Erro ao criar o usuário ${user.name}.`]);
      }
      // Atualiza a barra de progresso
      setProgress(((i + 1) / totalUsers * 100).toFixed(1));
    }

    
    setLoading(false);
  };



  const handleUpload = async () => {

    const resp = await checkBefore()

    if (!resp) {
      return
    }

    setLoading(true);
    try {
      const updatedData = data.map(item => ({
        name: item.nome,
        email: item.email,
        position: item.posicao,
        phone: null

      }));

      // await requester.profiles.createUserBulk(updatedData.map(({ errors, ...rest }) => rest));
      await handleBatchSubmitProgress(updatedData)
      setData([]);
      // setErrors([]);

      setSuccessMsg("Operação Finalizada");
    } catch (err) {
      setSuccessMsg(null);
      setErrors(["Erro ao processar o arquivo"]);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (data.length > 0) {
      setConfirmClose(true);
    } else {
      setOpen(false);
      setData([]);
      setErrors([]);
      setSuccessMsg(null);
    }
  };

  const confirmAndClose = () => {
    setConfirmClose(false);
    setOpen(false);
    setData([]);
    setErrors([]);
    setSuccessMsg(null);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: ".csv, .xlsx" });

  return (
    <>
      <Turivius.TuriviusTooltip trigger="hover" placement="bottomEnd" message="Criação em Lote">
        <IconButton
          block
          className="btn-update-bulk"
          variant="contained"
          onClick={() => setOpen(true)}
          backgroundColor={TuriviusColors.turivius_light}
          color={TuriviusColors.white}
          icon={<FontAwesomeIcon className="rs-icon" icon={faLayerGroup} />}
        >
          Criação em Lote
        </IconButton>
      </Turivius.TuriviusTooltip>

      <Modal open={open} onClose={handleClose} backdrop="static" size="lg">
        <Modal.Header>
          <Modal.Title>Upload de Arquivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div {...getRootProps()} style={{ border: "2px dashed #0087F7", padding: "20px", cursor: "pointer" }}>
            <input {...getInputProps()} />
            <p>Arraste e solte um arquivo CSV ou XLSX aqui, ou clique para selecionar</p>
          </div>
          <br></br>
          {errors.length > 0 && (
            <Message type="error" showIcon>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </Message>
          )}

          <br></br>
          {successMsg && <Message type="success" showIcon>
            <ul>
              {successMsg}
            </ul>
          </Message>}
          <br></br>

          {data.length > 0 && (
            <div style={{ marginTop: "20px" }}>
              <h5>Pré-visualização dos dados</h5>
              <Table data={data} autoHeight bordered cellBordered fluid>
                {headers.map((header, index) => (
                  <Column key={index} flexGrow={1} minWidth={100} align="center">
                    <HeaderCell>{header}</HeaderCell>
                    <CustomCell dataKey={header} />
                  </Column>
                ))}

              </Table>
            </div>
          )}

          {loading && (
            <div style={{ margin: "10px 0", width: '90%' }} >
              <h5>Progresso:</h5>
              <Progress.Line percent={progress} status={progress < 100 ? "active" : "success"} />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancelar
          </Button>
          <Button onClick={checkBefore} appearance="ghost" loading={loading} disabled={data.length === 0}>
            Verificar antes de criar!
          </Button>
          <Button onClick={handleUpload} appearance="primary" loading={loading} disabled={data.length === 0}>
            Criar usuários
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal open={confirmClose} onClose={() => setConfirmClose(false)} backdrop="static">
        <Modal.Header>
          <Modal.Title>Confirmar Fechamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>Você tem dados carregados. Deseja descartar antes de sair?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setConfirmClose(false)} appearance="subtle">
            Cancelar
          </Button>
          <Button onClick={confirmAndClose} appearance="primary" color="red">
            Descartar e Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BulkCreateButton;
