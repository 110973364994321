import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Grid, Row, Col, Input, Dropdown } from "rsuite";
import { Turivius } from "../../ui";
import { requester } from "../../requesters";
import { withRouter } from "react-router-dom";
import * as XLSX from "xlsx";
import "./styles.scss"; // Importando o CSS

const infos = {
    columns: [
        // { key: "name", label: "Nome", fixed: false, width: 300, resizable: true },
        { key: "initials", label: "Sigla", fixed: false, width: 150, resizable: true },
        { key: "jurisdiction", label: "Jurisdição", fixed: false, width: 150, resizable: true },
        {
            key: "doc_count_filtered",
            label: "Qtd na Turivius",
            fixed: false, width: 180, resizable: true,
            customCell: ({ rowData }) => <p>{rowData.doc_count_filtered || 0}</p>,
        },
        {
            key: "count_source",
            label: "Qtd na Fonte",
            fixed: false, width: 180, resizable: true,
            customCell: ({ rowData }) => (
                <p>{rowData?.count_source || 'Não encontrado'}</p>
            ),
        },
        {
            key: "last_entry_dt",
            label: "Última Captura de dados",
            fixed: false, width: 240, resizable: true,
            customCell: ({ rowData }) => (
                <p>{rowData.last_entry_dt ? new Date(rowData.last_entry_dt).toLocaleDateString("pt-BR") : "Sem dados"}</p>
            ),
        },
        {
            key: "crawler_status",
            label: "Crawler?",
            fixed: false, width: 150, resizable: true,
            customCell: ({ rowData }) => <p className={`crawler-status ${rowData.crawler_status == 'OK' ? 'ok' : 'error'}`}>{rowData.crawler_status == 'OK' ? 'OK' : 'ERROR'}</p>,
        },
        {
            key: "sumarizable",
            label: "Resumo IA ?",
            fixed: false, width: 150, resizable: true,
            customCell: ({ rowData }) => <p>{<FontAwesomeIcon
                className="rs-icon"
                icon={rowData.sumarizable ? faCheck : faXmark}
            />}</p>,
        },
        // New column for final status
        {
            key: "final_status",
            label: "Status Final",
            fixed: false, width: 190, resizable: true,
            customCell: ({ rowData }) => {
                // Definindo a classe com base no status
                let statusClass = '';
                switch (rowData.final_status) {
                    case 'OK':
                        statusClass = 'status-ok';
                        break;
                    case 'ATENÇÃO':
                        statusClass = 'status-atenção';
                        break;
                    case 'MAL FUNCIONAMENTO':
                        statusClass = 'status-com-furo';
                        break;
                    case 'QUEBRADO':
                        statusClass = 'status-quebrado';
                        break;
                    default:
                        statusClass = '';
                }

                return (
                    <div className={statusClass}>
                        {rowData.final_status}
                    </div>
                );
            },
        }

    ],
};

const EntitiesPage = (props) => {
    const [entities, setEntities] = useState([]); // Conjunto total de dados
    const [filteredEntities, setFilteredEntities] = useState([]); // Valores exibidos na tabela
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState(""); // Filtro de pesquisa por nome/sigla
    const [dateFilter, setDateFilter] = useState(null); // Período de atualização
    const [dataPresenceFilter, setDataPresenceFilter] = useState("sim"); // Teve ou não dados
    const pageSize = 100; // Total de elementos na tabela
    const [activePage, setActivePage] = useState(1); // controle de página ativa do componente (fora da tabela)
    const [currentTotal, setCurrentTotal] = useState(0); // Total de elementos (Com filtro)

    // estado do filtro de data
    const [selectedDateFilter, setSelectedDateFilter] = useState("Todos");
    const [selectedDataPresence, setSelectedDataPresence] = useState("Sim");

    // estado do resumo de IA
    const [aiSummaryFilter, setAiSummaryFilter] = useState(null);
    const [selectedAiSummary, setSelectedAiSummary] = useState("Todos");

    // estado do filtro de jurisdição
    const [jurisdictionFilter, setJurisdictionFilter] = useState(null);
    const [selectedJurisdiction, setSelectedJurisdiction] = useState("Todas");

    // Bot status
    const [crawlerStatusFilter, setCrawlerStatusFilter] = useState("Todos"); // "Todos" by default
    const [selectedCrawlerStatus, setSelectedCrawlerStatus] = useState("Todos"); // Estado para o título do filtro (o texto exibido)

    // Counting crawler
    const [brokenCrawlersCount, setBrokenCrawlersCount] = useState(0);
    const [notUpToDateCrawlersCount, setNotUpToDateCrawlersCount] = useState(0);
    const [realBroken, setRealBroken] = useState(0);


    const [statusFilter, setStatusFilter] = useState("Todos"); // "Todos" by default
    const [selectedStatusFilter, setSelectedStatusFilter] = useState("Todos"); // "Todos" by default

    // Aplica um dos períodos fixos
    const applyDateFilter = (days) => {
        if (!days) {
            // Se os dias não foram informados ele reseta o filtro
            setDateFilter(null)
        } else {
            const today = new Date();
            const startDate = new Date();
            startDate.setDate(today.getDate() - days);
            setDateFilter([startDate, today]);
        }

    };



    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(filteredEntities);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Entidades");

        XLSX.writeFile(wb, "entidades.xlsx");
    };

    const changeGenericFilter = () => {
        setLoading(true);
        changePage(1);
        setLoading(false);
    };

    function keyPressSearch(e) {
        if (e.key === "Enter") {
            changeGenericFilter();
        }
    }

    // se qualquer filtro for mudado ele aciona e remapeia os dados
    useEffect(() => {
        changePage(1); // essa parte troca a página atual (no caso aplica os filtros já que não tem mais páginação)
    }, [entities, dateFilter, dataPresenceFilter, aiSummaryFilter, jurisdictionFilter, crawlerStatusFilter, statusFilter]);

    const changePage = (page) => {
        const [start, end] = [(page - 1) * pageSize, page * pageSize];

        // Primeiro filtra pelos dados que estão na barra de busca
        let filteredSearch = entities.filter(
            (entity) =>
                entity.name.toLowerCase().includes(search.toLowerCase()) ||
                entity.initials.toLowerCase().includes(search.toLowerCase())
        );

        // Filtro por status do crawler (OK, ERROR, or Todos)
        if (crawlerStatusFilter !== "Todos") {
            filteredSearch = filteredSearch.filter(entity => entity.crawler_status === crawlerStatusFilter);
        }

        // Filtrar pelo status final selecionado
        if (statusFilter !== "Todos") {
            filteredSearch = filteredSearch.filter(entity => entity.final_status === statusFilter);
        }

        // filtrar os que tem resumo de IA
        if (aiSummaryFilter !== null) {
            filteredSearch = filteredSearch.filter(entity => entity.sumarizable === aiSummaryFilter);
        }

        // filtrar por jurisdição
        if (jurisdictionFilter !== null) {
            filteredSearch = filteredSearch.filter(entity => entity.jurisdiction === jurisdictionFilter);
        }

        // Filtrar se teve ou não dados e a partir de qual data
        if (dateFilter && dataPresenceFilter !== null) {
            const thresholdDate = dateFilter[0]

            // Se ele quer dados que foram capturados
            if (dataPresenceFilter === "sim") {

                // Filtro aqueles que são maiores que a base da data
                filteredSearch = filteredSearch.filter((entity) => {
                    const entryDate = new Date(entity.last_entry_dt);
                    return entryDate >= thresholdDate;
                });

            } else if (dataPresenceFilter === "nao") {

                // Filtro aqueles que tem a data da ultima captura menor que a base da data
                filteredSearch = filteredSearch.filter((entity) => {
                    const entryDate = new Date(entity.last_entry_dt);
                    return entryDate < thresholdDate || isNaN(entryDate);
                });
            }
        }

        setFilteredEntities(filteredSearch.slice(start, end));
        setActivePage(page);
        setCurrentTotal(filteredSearch.length);
    };
    useEffect(() => {
        requester.cards
            .getCrawlersInfo({})
            .then((data) => {
                setEntities(data);
                setLoading(false);
                setCurrentTotal(data.length);

                const currentDate = new Date();
                currentDate.setDate(currentDate.getDate() - 7); // Add 7 days to current date to check if it's within 1 week

                // Add final status to each entity based on the logic provided
                const updatedEntities = data.map((entity) => {
                    const lastCapturedDate = new Date(entity.last_entry_dt);
                    const isUpToDate = lastCapturedDate >= currentDate; // If the last capture is within the last 7 days
                    const isBroken = entity.crawler_status === "ERROR"; // If crawler is broken

                    // Determine final status based on conditions
                    if (isUpToDate && !isBroken) {
                        entity.final_status = "OK";
                    } else if (!isUpToDate && !isBroken) {
                        entity.final_status = "ATENÇÃO";
                    } else if (isUpToDate && isBroken) {
                        entity.final_status = "MAL FUNCIONAMENTO";
                    } else {
                        entity.final_status = "QUEBRADO";
                    }

                    return entity;
                });

                // Update entities with the final status
                setEntities(updatedEntities);

                const uptodate_set = new Set()
                const broken_set = new Set()


                // Count broken crawlers (status 'ERROR')
                const brokenCrawlers = data.filter(entity => {

                    const error = entity.crawler_status === "ERROR"

                    if (error) {
                        broken_set.add(entity.id)
                    }

                    return error
                });
                setBrokenCrawlersCount(brokenCrawlers.length);


                // Adicionar 7 dias à data
                currentDate.setDate(currentDate.getDate() - 7);
                const notUpToDateCrawlers = data.filter(entity => {
                    const lastCapturedDate = new Date(entity.last_entry_dt); // Convert last_captured_at to Date object
                    const upToDateResult = lastCapturedDate < currentDate; // If the last capture date is before today

                    if (upToDateResult) {
                        uptodate_set.add(entity.id)
                    }

                    return upToDateResult
                });
                setNotUpToDateCrawlersCount(notUpToDateCrawlers.length);

                // Perform intersection (AND operation) to get common elements
                let intersection = new Set([...uptodate_set].filter(item => broken_set.has(item)))

                setRealBroken(intersection.size)

            })
            .catch((error) => {
                console.error("Erro ao buscar tribunais:", error);
                setLoading(false);
            });
    }, []);




    useEffect(() => {
        document.title = `Entidades`;
    }, []);

    return (
        <Grid fluid>
            <div className="container-entities">
                <Row className="entities-header">
                    <Col xs={24}>
                        <h1>Entidades</h1>
                    </Col>
                </Row>
                {/* Tags displaying counts of broken crawlers and not up-to-date crawlers */}
                <Row className="entities-status-tags">
                    <Col xs={24} sm={12} md={12} >
                        <Col xs={24} sm={12} md={6}>
                            <div className="status-tag">
                                <p><strong>Quebrados:</strong></p>
                                <span className="tag">{brokenCrawlersCount}</span>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <div className="status-tag">
                                <p><strong>Desatualizados:</strong></p>
                                <span className="tag">{notUpToDateCrawlersCount}</span>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12}>
                            <div className="status-tag">
                                <p><strong>Realmente Quebrados:</strong></p>
                                <span className="tag">{realBroken}</span>
                            </div>
                        </Col>
                    </Col>

                    <Col xs={24} sm={6} md={6}>
                        <Col xs={24} sm={22} md={22}>
                            <Button block appearance="success" className="button-search" loading={loading} onClick={changeGenericFilter}>
                                Buscar
                            </Button>
                        </Col>
                        <Col xs={24} sm={22} md={22}>
                            <Button block className="button-export" onClick={exportToExcel}>
                                Exportar para Excel
                            </Button>
                        </Col>
                    </Col>



                </Row>

                {/* Filtros */}
                <Row className="entities-filters">
                    {/* Filtro por presença de dados */}
                    <Col xs={24} sm={12} md={4}>
                        <div>
                            <p><strong>Foram Capturados:</strong></p>
                            <Dropdown title={selectedDataPresence}>
                                <Dropdown.Item onSelect={() => { setDataPresenceFilter("sim"); setSelectedDataPresence("Sim"); }}>
                                    Sim
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setDataPresenceFilter("nao"); setSelectedDataPresence("Não"); }}>
                                    Não
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </Col>

                    {/* Filtro de data */}
                    <Col xs={24} sm={10} md={4}>
                        <div>
                            <p><strong>Em qual data:</strong></p>
                            <Dropdown title={selectedDateFilter}>
                                <Dropdown.Item onSelect={() => { applyDateFilter(null); setSelectedDateFilter("Todos"); }}>
                                    Todos
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(1); setSelectedDateFilter("Ontem"); }}>
                                    Ontem
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(3); setSelectedDateFilter("Últimos 3 dias"); }}>
                                    Últimos 3 dias
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(7); setSelectedDateFilter("Última semana"); }}>
                                    Última semana
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(15); setSelectedDateFilter("Últimas 2 semanas"); }}>
                                    Últimas 2 semanas
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(30); setSelectedDateFilter("Últimos 30 dias"); }}>
                                    Últimos 30 dias
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(90); setSelectedDateFilter("Últimos 90 dias"); }}>
                                    Últimos 90 dias
                                </Dropdown.Item>

                            </Dropdown>
                        </div>

                    </Col>

                    <Col xs={24} sm={12} md={3}>
                        <div>
                            <p><strong>Status Final:</strong></p>
                            <Dropdown title={selectedStatusFilter}>
                                <Dropdown.Item onSelect={() => { setStatusFilter("Todos"); setSelectedStatusFilter("Todos"); }}>
                                    Todos
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setStatusFilter("OK"); setSelectedStatusFilter("OK"); }}>
                                    OK
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setStatusFilter("ATENÇÃO"); setSelectedStatusFilter("ATENÇÃO"); }}>
                                    ATENÇÃO
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setStatusFilter("MAL FUNCIONAMENTO"); setSelectedStatusFilter("MAL FUNCIONAMENTO"); }}>
                                    MAL FUNCIONAMENTO
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setStatusFilter("QUEBRADO"); setSelectedStatusFilter("QUEBRADO"); }}>
                                    QUEBRADO
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </Col>


                    <Col xs={24} sm={10} md={3}>
                        <div>
                            <p><strong>Resumo de IA:</strong></p>
                            <Dropdown title={selectedAiSummary}>
                                <Dropdown.Item onSelect={() => { setAiSummaryFilter(null); setSelectedAiSummary("Todos"); }}>
                                    Todos
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setAiSummaryFilter(true); setSelectedAiSummary("Com Resumo"); }}>
                                    Com Resumo
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setAiSummaryFilter(false); setSelectedAiSummary("Sem Resumo"); }}>
                                    Sem Resumo
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={3}>
                        <div>
                            <p><strong>Status do Crawler:</strong></p>
                            <Dropdown title={selectedCrawlerStatus}>
                                <Dropdown.Item onSelect={() => { setCrawlerStatusFilter("Todos"); setSelectedCrawlerStatus("Todos"); }}>
                                    Todos
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setCrawlerStatusFilter("OK"); setSelectedCrawlerStatus("OK"); }}>
                                    OK
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setCrawlerStatusFilter("ERROR"); setSelectedCrawlerStatus("ERROR"); }}>
                                    ERROR
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </Col>


                    <Col xs={24} sm={4} md={3}>
                        <div>
                            <p><strong>Jurisdição:</strong></p>
                            <Dropdown title={selectedJurisdiction}>
                                <Dropdown.Item onSelect={() => { setJurisdictionFilter(null); setSelectedJurisdiction("Todas"); }}>
                                    Todas
                                </Dropdown.Item>
                                {Array.from(new Set(entities.map(entity => entity.jurisdiction))) // Obtém valores únicos
                                    .map(jurisdiction => (
                                        <Dropdown.Item
                                            key={jurisdiction}
                                            onSelect={() => { setJurisdictionFilter(jurisdiction); setSelectedJurisdiction(jurisdiction); }}
                                        >
                                            {jurisdiction}
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown>
                        </div>
                    </Col>


                    {/* Filtro de pesquisa textual */}
                    <Col xs={24} sm={12} md={3}>
                        <p><strong>Busca Textual</strong></p>
                        <Input
                            placeholder="Filtrar por nome"
                            className="input-search"
                            value={search}
                            onChange={setSearch}
                            onKeyUp={keyPressSearch}
                        />
                    </Col>


                </Row>

                {/* Tabela de Entidades */}
                <div className="entities-body">
                    <Turivius.TuriviusTable
                        data={filteredEntities}
                        columns={infos.columns}
                        loading={loading}
                        height={550}
                        fixed
                        wordWrap
                        // paginate
                        onChangePage={(page) => changePage(page)}
                        total={currentTotal}
                        activePage={activePage}

                    />
                </div>
            </div>
        </Grid>
    );
};

export default withRouter(EntitiesPage);
