import React, { useEffect } from "react";
// import TuriviusColors from "../../vars.module.scss"
import { Nav } from "rsuite";
import { withRouter } from "react-router-dom";
import "./style.scss";
import { useRef } from "react";

const NavCompany = ({
  tabDefault,
  active,
  onSelect,
  setActive,
  index,
  ...props
}) => {
  const updateURL = () => {
    const params = index.location.hash || tabDefault;
    setActive(params.replace("#", ""));
  };

  useEffect(() => {
    updateURL();
  });

  return (
    <Nav className="navbar" {...props} activeKey={active} onSelect={onSelect}>
      <Nav.Item eventKey="company" href="#company">
        Empresa
      </Nav.Item>
      <Nav.Item eventKey="users" href="#users">
        Usuários
      </Nav.Item>
      <Nav.Item eventKey="asaas" href="#asaas">
        ASAAS
      </Nav.Item>
      <Nav.Item eventKey="espurio" href="#espurio">
        Contas Espúrias
      </Nav.Item>
    </Nav>
  );
};

const NavUsers = ({
  tabDefault,
  active,
  onSelect,
  index,
  tabsData,
  ...props
}) => {
  const updateURL = () => {
    const params = index.location.hash || tabDefault;
    onSelect(params.replace("#", ""));
  };

  useEffect(() => {
    updateURL();
  });

  return (
    <Nav className="navbar" {...props} activeKey={active} onSelect={onSelect}>
      {tabsData.map((tab) => (
        <Nav.Item eventKey={tab.eventKey} href={tab.href}>
          {tab.name}
        </Nav.Item>
      ))}
    </Nav>
  );
};

export { NavCompany, NavUsers };
